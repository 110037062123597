<template>
  <!-- Modal -->
  <Modal v-if="modal" class="RequestCollect" @close="close">
    <!-- Header -->
    <template #header>
      <div class="RequestCollect__Name">
        <div>Solicitação de coleta avulsa</div>
      </div>        
    </template>
    <!-- Body -->
    <template #body>
      <div v-if="!requestedCollect">
        <p>Você está solicitando uma coleta avulsa no seller <TextCopy :value="data.name" /> para hoje, tem certeza disso?</p>
        <div class="RequestCollect__Buttons">
          <BaseButton
            filled
            color="dark"
            text-color="white"
            label="Cancelar"
            @click="close"
          />
          <BaseButton
            filled
            color="dark"
            text-color="white"
            label="Confirmar"
            @click="save"
          />
        </div>
      </div>
      <div v-else>
        <p>Solicitação de coleta confirmada! Coleta está sendo gerada e estará disponivel em instantes na tela Rotas para ser atrelada a um motorista.</p>
      </div>
    </template>
  </Modal>
</template>
<script>
import { sellers } from '@/api'
import { mapActions } from 'vuex'
import { BaseButton } from '@/components/atoms'
import { Modal, TextCopy } from '@/components/molecules'

export default {
  name: 'RequestCollect',
  components: {
    Modal,
    TextCopy,
    BaseButton
  },
  props: {
    data: {
      type: Object,
      default: null,
      required: true
    }
  },
  data() {
    return {
      //Helpers
      modal: false,
      requestedCollect: false
    }
  },
  computed:{
  },
  watch: {
  },
  methods: {

    ...mapActions({
      setLoading: 'loading/setLoading'
    }),

    //Handlers
    open() {
      this.modal = true
      this.requestedCollect = false
      this.$emit('open')
    },
    close() {
      this.modal = false
      this.requestedCollect = false
      this.$emit('close')
    },
    save() {
      let body = {
        seller_code: this.data.seller_code,
        collection_type: 'offline'
      }
      this.setLoading(true)
      sellers.requestCollect(body, () => {
        this.requestedCollect = true
        this.$emit('search')
      }, () => {this.setLoading(false)}, () => {})
    },
  }
}
</script>
<style lang="scss" scoped>
.RequestCollect {
  /deep/ .Modal__container {
    position: relative;
    width: auto;
    max-width: 660px;
    height: auto;
  }
  /deep/ .Modal__containerBody {
    padding: 20px;
  }
  &__Name {
    // font-weight: $font-weight-bold;
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0 8px;
  }
  &__Buttons {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    /deep/ .BaseButton {
      max-width: 200px;
      margin: 10px;
    }
  }
}
</style>