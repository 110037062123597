<template>
  <main class="Sellers">
    <TheSubHeader 
      icon="store-alt" 
      type="filter"
      title="Sellers"
      page-title-tag="Sellers"
      organizer-gap="1rem"
      organizer-columns="2fr 1fr 2fr 1fr"
    >
      <template #afterButtons>
        <AsyncExportButton
          v-if="permissions && permissions.sellers && permissions.sellers.exportSellersButton"
          name="sellers"
          name-report="Sellers"
          icon="export"        
          color="dark"
          label="Exportar Sellers"
          label-loading="Exportando Sellers"
          :track="trackSubheaderButtonString('Sellers', 'Export')"
        />
        <BaseButton
          v-if="permissions && permissions.sellersVips && permissions.sellersVips.isVisible"
          icon="medal"
          color="dark"
          label="Sellers Vips"
          :track="trackSubheaderButtonString('Sellers', 'Sellers Vips')"
          @click="goTo('/sellers/vips')"
        />
        <BaseButton
          v-if="permissions && permissions.sellers && permissions.sellers.storesPageButton"
          icon="store-alt"
          color="dark"
          label="Lojistas"
          :track="trackSubheaderButtonString('Sellers', 'Stores')"
          @click="goTo('/stores')"
        />
        
        <BaseButton
          v-if="permissions && permissions.sellers && permissions.sellers.packagesIncomingPageButton"
          icon="dashboard-package-request"
          color="primary-base"
          label="Recebimento de Pacotes"
          :track="trackSubheaderButtonString('Sellers', 'Packages Incoming')"
          @click="goTo('/packages/incoming')"
        />
      </template>
      <template #filter>
        <!-- Sellers -->
        <BaseTreeSelect
          v-model="filters.searchSellers"
          name="Sellers"
          label="Sellers"
          placeholder="Selecione"
          multiple
          :tags="true"
          :min-search-length="2"
          suggestions="searchSellers"
          @input="resetSearch()"
        />
        <!-- Status -->
        <BaseTreeSelect
          v-model="filters.categorySelected"
          name="Categoria"
          label="Categoria"
          placeholder="Selecione"
          :clearable="true"
          :select-options="categoriesOpt"
          @input="resetSearch()"
        />
        <!-- Stores -->
        <BaseTreeSelect
          ref="lojista"
          v-model="filters.searchValue"
          name="Lojista"
          label="Lojista"
          placeholder="Selecione"
          multiple
          :select-options="null"
          :fetch-options="'stores/fetchStoresAsync'"
          @input="resetSearch()"
        />
        <!-- Status -->
        <BaseTreeSelect
          v-model="filters.statusSelected"
          name="Status"
          label="Status"
          placeholder="Selecione"
          :clearable="false"
          :select-options="statusOpt"
          @input="resetSearch()"
        />
      </template>
    </TheSubHeader>
    <div class="Sellers__content">
      <div class="Sellers__table">
        <DataTable
          :header="sellersTable.data.headers"
          :table="sellersTable.data.content"
          is-sticky
          @see="editSellers"
          @changeLocation="changeLocation"
          @requestCollect="requestCollect"
          @warning="setError('Ocorreu um erro na última coleta avulsa solicitada. Por favor tente novamente.')"
        />
        <Pagination @page="search()" @paginate="search()" />
      </div>
    </div>

    <LocationChange ref="location" :data="locationData" title="do seller" @save="saveNewLocation" />
    <RequestCollect ref="requestCollect" :data="sellerData" @search="search" />
  </main>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { BaseTreeSelect, BaseButton } from '@/components/atoms'
import { DataTable, Pagination, LocationChange, AsyncExportButton } from '@/components/molecules'
import { TheSubHeader } from '@/components/organisms'
import { sellers } from '@/api'
import RequestCollect from '@/views/sellers/RequestCollect'

export default {
  name: 'Sellers',
  components: {
    TheSubHeader,
    DataTable,
    Pagination,
    BaseTreeSelect,
    BaseButton,
    LocationChange,
    AsyncExportButton,
    RequestCollect
  },
  
  data() {
    return {
      filters: {
        statusSelected: { id: 0, name:'Todos' },
        categorySelected: null,
        searchValue: [],
        searchSellers: [],
      },
      currentPage: 1,
      statusOpt: [{ id: 0, name:'Todos' },{ id: 1, name:'Ativo' }, { id: 2, name:'Inativo' }],
      categoriesOpt: [{ id: 'Normal', name:'Normal' },{ id: 'VIP', name:'VIP' }],

      //Location Change
      locationData: {},

      //Request Collect
      sellerData: {},

      updating: false,
      time: null,
      timeOutUpdate: 20 * 1000,
    }
  },
  
  computed:{
    ...mapState({
      sellersTable: state => state.sellers.sellersTable,
      queryString: state => state.queryString.queryString,
      page: state => state.pagination.page,
      paginate: state => state.pagination.paginate,
      permissions: state => state.auth.permissions
    }),
  },

  mounted(){
    this.search()
  },

  methods:{
    ...mapActions({
      fetchSellersTable: 'sellers/fetchSellersTable',
      setParameters: 'queryString/setParameters',
      clearPagination: 'pagination/clearPagination',
      setPagination: 'pagination/setPagination',
      setLoading: 'loading/setLoading',
      setError: 'error/setError',
    }),

    resetSearch() {
      this.clearPagination().then(() => this.search())
    },

    search(loading = true){

      if (loading) {
        this.setLoading(true)
      }

      const params = {
        obj: {
          id: this.filters.searchSellers,
          status_id: this.filters.statusSelected.id,
          seller_category: this.filters.categorySelected,
          ['filter[stores][store_id]']: this.$refs?.lojista?.checkSelectAll ? [] : this.filters.searchValue,
        },
        paginate: this.paginate,
        page: this.page
      }
      this.setParameters(this.filters)
      this.fetchSellersTable(params)
        .then(() =>{
          this.setLoading(false)
          this.setPagination(this.sellersTable)
          this.checkSellersTable()
        })
    },

    checkSellersTable() {
      if (
        JSON.stringify(this.sellersTable).indexOf('loading') > 0 && 
        this.$route.name === 'sellers'
      ) {
        if (!this.updating) {
          this.updating = true
          this.time = setTimeout(() => {
            this.search(false)
            this.updating = false
          }, this.timeOutUpdate + this.time)
          this.setPagination(this.sellersTable)
        }
      } else {
        clearTimeout(this.time)
        this.setPagination(this.sellersTable)
      }
    },

    editSellers(data){
      this.openInNewTab(`/sellers/${data.id}`)
    },

    //Location Change
    changeLocation(data) {
      this.locationData = data
      this.$refs.location.open()
    },

    saveNewLocation(map) {   
      this.setLoading(true)
      sellers.saveSellerLocation(map, () => {
        this.search()
      }, () => {this.setLoading(false)}, () => {})
      
    },

    //Request Collect
    requestCollect(data) {
      this.sellerData = data
      this.$refs.requestCollect.open()
    },

    goTo(route) {
      this.$router.push(route)
    }
  }
}
</script>

<style lang="scss" scoped>
.Sellers {
  &__content {
    display: grid;
    grid-template-columns: 1fr minmax(min-content, 1180px) 1fr;
    grid-template-areas: ". main .";
    grid-column-gap: 1rem;
  }

  &__table {
    grid-area: main;
    margin-bottom: 50px;
  }

  &__Location {
    /deep/ .Modal__container {
      position: relative;
    }
    .name {
      // font-weight: $font-weight-bold;
      display: flex;
      height: 100%;
      align-items: center;
      padding: 0 8px;
    }

    &__Actions {
      position: absolute;
      bottom: 20px;
      left: calc(50% - 150px);
      width: 300px;

      &__LatLong {
        display: flex;
        gap: 10px;
      }

      .saveLocation {
        margin-top: 10px;
        &:hover {
          opacity: 1
        }
      }

    }
  }
}
</style>